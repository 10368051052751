import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingStates: { [key: string]: BehaviorSubject<boolean> } = {};

  /**
   *
   * @param key unique string to identify the loading state.
   * @returns Observable<boolean>
   */
  getLoadingObservable(key: string): Observable<boolean> {
    if (!this.loadingStates[key]) {
      this.loadingStates[key] = new BehaviorSubject<boolean>(false);
    }
    return this.loadingStates[key].asObservable();
  }

  /**
   * Set the loading state to true or false.
   * @param key
   * @param isLoading
   */
  setLoading(key: string, isLoading: boolean): void {
    if (!this.loadingStates[key]) {
      this.loadingStates[key] = new BehaviorSubject<boolean>(false);
    }
    this.loadingStates[key].next(isLoading);
  }

  /**
   * Get the combined loading state of all loading states.
   * @returns Observable<boolean>
   */
  getCombinedLoadingObservable(): Observable<boolean> {
    const observables = Object.values(this.loadingStates).map((bs) =>
      bs.asObservable()
    );
    return combineLatest(observables).pipe(
      map((loadingStates: boolean[]) =>
        loadingStates.some((isLoading) => isLoading)
      )
    );
  }
}
